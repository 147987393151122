/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';

import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueFormWizard from 'vue-form-wizard';
// router setup
import { router } from './routes/router';
import store from './store';
import i18n from './i18n';
import './registerServiceWorker';
import './plugins/mixins';

import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
// import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
console.log('WARNING you are in : ' + process.env.NODE_ENV + ' environment');

// if (process.env.NODE_ENV === "development") {
//   console.log("[Warning] rewriting the Api routes in development environment");
//   aws_exports.aws_cloud_logic_custom = aws_exports.aws_cloud_logic_custom.map(
//     config => {
//       if (config.name === "webAppAPI") {
//         config.endpoint = `http://${window.location.hostname}:4000/api`;
//       }
//       return config;
//     }
//   );
// }

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

const disableMixin = {
  mounted() {
    if (this.$el && this.$el.classList) {
      // console.log(this.$el.outerHTML);
      if (store.getters.getTenantAbilities('disable', this.$el.outerHTML)) {
        console.log('hiding element');
        this.$el.classList.add('disabled');
      }
    }
  }
};

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Amplify.configure(aws_exports);
Auth.configure(aws_exports);

Vue.mixin(disableMixin);

// plugin setup
Vue.use(VueFormWizard);
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  store,
  Auth,
  render: h => h(App)
});
