<template>
  <div class="loading-text-center">
    <scale-loader :loading="true" :color="'#ff8678'" :height="'70px'" :width="'5px'"></scale-loader>

    <h3>loading database...</h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { databaseChoresTypes } from '../../constants/types';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

export default {
  name: 'LoadingScreen',
  components: {
    ScaleLoader
  },
  computed: {
    ...mapGetters({
      // count: databaseChoresTypes.getters.getDBStatus
    }),
    loading() {
      return this.loader(databaseChoresTypes.actions.ping);
    }
  },
  methods: {
    async ping() {
      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve('timeout');
        }, 10000);
      });

      const pingResponse = this.$store.dispatch(databaseChoresTypes.dispatchers.ping, {});

      try {
        const response = await Promise.race([pingResponse, timeoutPromise]);
        console.log(response.data.value);

        if (response.data.value === 1) {
          console.log('Received a response from Customers API');
          this.$router.push('/');
        } else {
          console.log('No response from Customers API.');
        }
      } catch (error) {
        swal
          .fire({
            title: 'Error!',
            text: 'Database is not available, please contact support',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger btn-fill'
            },
            buttonsStyling: false
          })
          .then(() => {
            Auth.signOut();
            this.$router.push('/login');
          });
      }
    }
  },
  created() {
    this.ping();
  }
};
</script>

<style>
.loading-text-center {
  margin-top: 35vh;
  text-align: center;
}

.loading-text-center h3 {
  margin-bottom: 0;
}
</style>
