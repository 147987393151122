import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';
import { globalTypes } from '../../constants/types';

const { setTenantAbilities } = globalTypes.actions;

export const actions = {
  // saveError: {
  //   root: true,
  //   handler({ commit }, type, message, location) {
  //     commit(setError, type, message, location, {
  //       root: true
  //     });
  //   }
  // }
  [setTenantAbilities]: async ({ commit }) => {
    const response = await query({
      commit,
      api: $api.tenant.ability.get(),
      options: {
        loader: setTenantAbilities,
        promise: true,
        mutation: setTenantAbilities
      }
    });
    commit(setTenantAbilities, { response });
    return response;
  }
};
