<template>
  <BaseNav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button />
      </div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1" />
          <span class="navbar-toggler-bar bar2" />
          <span class="navbar-toggler-bar bar3" />
        </button>
      </div>
      <a class="navbar-brand" ref="favicon.ico" href="/">
        <img src="/favicon.ico" style="width: 40px" /> Dodobird AI
      </a>
      <!--{{routeName}} -->
    </div>
    <ul class="navbar-nav ml-auto">
      <base-dropdown tag="li" title-tag="a" class="nav-item" title-classes="nav-link" menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="photo">
            <img src="/img/default-avatar.png" />
          </div>
          <b class="caret d-none d-lg-block d-xl-block" />
          <p class="d-lg-none">Log out</p>
        </template>
        <li class="nav-link">
          <a href="/profile" class="nav-item dropdown-item">Profile</a>
        </li>
        <li class="nav-link">
          <a href="/settings" class="nav-item dropdown-item">Settings</a>
        </li>
        <div class="dropdown-divider" />
        <li class="nav-link">
          <button id="custom-signout" @click="resetStore">SIGNOUT</button>
          <!-- <amplify-sign-out @click="resetStore" /> -->
        </li>
      </base-dropdown>
    </ul>
  </BaseNav>
</template>
<script>
import BaseNav from '../../components/Navbar/BaseNav.vue';
import SidebarToggleButton from './SidebarToggleButton.vue';
import { Auth } from 'aws-amplify';

export default {
  components: {
    SidebarToggleButton,
    BaseNav
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  methods: {
    async resetStore() {
      await Auth.signOut();
      this.$store.commit('resetState');
      window.location.href = '/dashboard';
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
#custom-signout {
  width: 100%;
  padding: 10px;
  background: #ff6a8c;
  color: white;
  font-weight: bold;
  border: 0px;
}

#custom-signout:hover {
  background: #ef5879;
}
</style>
