/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (globalTypes.dispatchers.getCustomer, ...)
 */

export const globalTypes = {
  module: 'global',
  getters: {
    // Get errors
    get getError() {
      return 'getError'; //${globalTypes.module} this time I want the module to be global and not namespaced
    },
    get getLoader() {
      return 'getLoader';
    },
    get getTenant() {
      return 'getTenant';
    },
    get getTenantAbilities() {
      return 'getTenantAbilities';
    }
  },
  actions: {
    saveError: 'SAVE_ERROR',
    setError: 'SET_ERROR',
    setLoading: 'SET_LOADING',
    setTenantInfo: 'SET_TENANT_INFORMATION',
    setTenantAbilities: 'SET_TENANT_ABILITIES'
  },
  dispatchers: {
    get setError() {
      return `${globalTypes.actions.setError}`;
    },

    get saveError() {
      return `${globalTypes.actions.saveError}`;
    },

    get setLoading() {
      return `${globalTypes.actions.setLoading}`;
    },

    get setTenantInfo() {
      return `${globalTypes.actions.setTenantInfo}`;
    },
    get setTenantAbilities() {
      return `${globalTypes.actions.setTenantAbilities}`;
    }
  }
};
