<template>
  <div class="d-flex flex-column w-100 align-items-center">
    <p class="font-weight-bold">or</p>
    <button class="bsk-btn bsk-btn-default w-100 my-2 disabled">
      <i class="fab fa-microsoft"></i> Sign {{ isSignUp ? 'up' : 'in' }} with Microsoft
    </button>
    <button class="bsk-btn bsk-btn-default w-100 my-2 disabled">
      <i class="fab fa-google"></i> Sign {{ isSignUp ? 'up' : 'in' }} with Google
    </button>
  </div>
</template>
<script>
export default {
  name: 'ExternalIdentityProvider',
  props: {
    action: {
      type: String,
      default: 'signUp',
      description: 'Action to perform when external identity provider is selected'
    }
  },
  computed: {
    isSignUp() {
      return this.action === 'signUp';
    }
  }
};
</script>
<style scoped>
.bsk-btn {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bsk-btn-default {
  background-color: #ffffff;
  color: #5e5e5e;
  border: 1px solid #5e5e5e;
}

.bsk-btn-default:hover {
  background-color: #f0f0f0;
}
</style>
