<template>
  <div class="vh-100 vw-100 auth-purple-glow-background">
    <div class="container h-100">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <ValidationObserver v-slot="{ handleSubmit }" class="auth-form">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-white p-3">
              <template slot="header">
                <h1 class="card-title font-weight-bold">Sign in with your account</h1>
              </template>

              <div>
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                  <label for="email">Email</label>
                  <base-input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>

                <PasswordInput
                  v-model="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  rules="required"
                />

                <base-button native-type="submit" class="auth-submit-button" size="lg" block :disabled="isLoading">
                  <span v-if="!isLoading">Sign In</span>
                  <i v-else class="fa fa-spinner fa-spin"></i>
                </base-button>

                <ExternalIdentityProvider :action="'signIn'" />
              </div>
              <FormFooter :leftSide="''" :rightSide="'Forgot Password'" />
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import ExternalIdentityProvider from '@/components/auth/ExternalIdentityProvider.vue';
import FormFooter from '@/components/auth/FormFooter.vue';
import PasswordInput from '@/components/auth/InputPassword.vue';

extend('email', email);
extend('required', required);

export default {
  name: 'login-form-comp',
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      email: '',
      password: ''
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    ExternalIdentityProvider,
    FormFooter,
    PasswordInput
  },
  methods: {
    submit() {
      this.$emit('login', { email: this.email, password: this.password });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>
