import { campaignAnalyticsTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const { setLiveCustomerCount, setLiveItemCount, setEmailSent, setEmailBounce, setEmailOpen, setEmailDelivery } =
  campaignAnalyticsTypes.actions;

export const actions = {
  [setLiveCustomerCount]: ({ commit }, { seg }) => {
    query({
      commit,
      api: $api.analytics.campaign.liveCustomerCount.get({
        segments: seg && seg.length > 0 ? `[${seg.join(',')}]` : null
      }),
      options: {
        mutation: setLiveCustomerCount
      }
    });
  },

  [setLiveItemCount]: ({ commit }, { includedFilters, excludedFilters }) => {
    query({
      commit,
      api: $api.analytics.campaign.liveItemCount.get({
        includedFilters: includedFilters && includedFilters.length > 0 ? `[${includedFilters.join(',')}]` : null,
        excludedFilters: excludedFilters && excludedFilters.length > 0 ? `[${excludedFilters.join(',')}]` : null
      }),
      options: {
        mutation: setLiveItemCount
      }
    });
  },

  [setEmailSent]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.analytics.campaign.emailSent.get(null, id),
      options: {
        mutation: setEmailSent
      }
    });
  },

  [setEmailBounce]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.analytics.campaign.emailBounce.get(null, id),
      options: {
        mutation: setEmailBounce
      }
    });
  },

  [setEmailOpen]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.analytics.campaign.emailOpen.get(null, id),
      options: {
        mutation: setEmailOpen
      }
    });
  },

  [setEmailDelivery]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.analytics.campaign.emailDelivery.get(null, id),
      options: {
        mutation: setEmailDelivery
      }
    });
  }
};
