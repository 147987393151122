import { campaignAnalyticsTypes } from '../../constants/types';
import Vue from 'vue';

const { setLiveCustomerCount, setLiveItemCount, setEmailSent, setEmailBounce, setEmailOpen, setEmailDelivery } =
  campaignAnalyticsTypes.actions;

export const mutations = {
  [setLiveCustomerCount]: (state, { response }) => {
    Vue.set(state, 'liveCustomerCount', response.data.value);
  },
  [setLiveItemCount]: (state, { response }) => {
    Vue.set(state, 'liveItemCount', response.data.value);
  },
  [setEmailSent]: (state, { response }) => {
    Vue.set(state, 'emailSent', response.data.value);
  },
  [setEmailBounce]: (state, { response }) => {
    Vue.set(state, 'emailBounce', response.data.value);
  },
  [setEmailOpen]: (state, { response }) => {
    Vue.set(state, 'emailOpen', response.data.value);
  },
  [setEmailDelivery]: (state, { response }) => {
    Vue.set(state, 'emailDelivery', response.data.value);
  }
};
