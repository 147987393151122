import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const campaignAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getLiveCustomerCount: state => state.liveCustomerCount,
    getLiveItemCount: state => state.liveItemCount,
    getEmailSent: state => state.emailSent,
    getEmailOpen: state => state.emailOpen,
    getEmailDelivery: state => state.emailDelivery,
    getEmailBounce: state => state.emailBounce
  },
  actions,
  mutations
};
