import VueRouter from 'vue-router';
import routes from './routes';
import { Auth } from 'aws-amplify';
import store from '../store';
import { globalTypes } from '../constants/types';

// configure router
export const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

/**
 * Routes that certain roles don't have access to
 */
export const prevents = {
  viewer: [],
  admin: []
};

const hasAccess = (to, role) => {
  if (prevents[role].indexOf(to.name) > -1) {
    return false;
  } else {
    return true;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const authenticated = await Auth.currentAuthenticatedUser();

      if (!authenticated) next('/login');
      else {
        const tenantId = authenticated.attributes['custom:tenant_id'];
        const role = authenticated.attributes['custom:role'];

        const passed = hasAccess(to, role);
        localStorage.setItem('role', role);

        if ((!tenantId && to.path !== '/login') || !passed) {
          next('/login');
        } else {
          await store.dispatch(globalTypes.dispatchers.setTenantAbilities);
          const can =
            store.getters.getTenantAbilities('disable', 'sidebar/' + to.name) ||
            store.getters.getTenantAbilities('disable', 'page/' + to.name);
          if (!can) {
            next();
          } else {
            next('/not-found');
          }
        }
      }
    } catch {
      if (to.path !== '/login') {
        next('/login');
      }
    }
  } else {
    if (to.name === 'sign-up' || to.name === 'forgot-password') {
      try {
        const authenticated = await Auth.currentAuthenticatedUser();
        if (authenticated) next('/');
      } catch {
        next();
      }
    } else next();
  }
});

export default router;
