export const getDefaultState = () => {
  return {
    liveCustomerCount: '',
    liveItemCount: '',
    emailSent: '',
    emailOpen: '',
    emailDelivery: '',
    emailBounce: ''
  };
};
