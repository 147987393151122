/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const campaignAnalyticsTypes = {
  module: 'campaignAnalytics',
  getters: {
    get getLiveCustomerCount() {
      return `${campaignAnalyticsTypes.module}/getLiveCustomerCount`;
    },
    get getLiveItemCount() {
      return `${campaignAnalyticsTypes.module}/getLiveItemCount`;
    },
    get getEmailSent() {
      return `${campaignAnalyticsTypes.module}/getEmailSent`;
    },
    get getEmailOpen() {
      return `${campaignAnalyticsTypes.module}/getEmailOpen`;
    },
    get getEmailDelivery() {
      return `${campaignAnalyticsTypes.module}/getEmailDelivery`;
    },
    get getEmailBounce() {
      return `${campaignAnalyticsTypes.module}/getEmailBounce`;
    }
  },
  actions: {
    setLiveCustomerCount: 'SET_LIVE_CUSTOMER_COUNT',
    setLiveItemCount: 'SET_LIVE_ITEM_COUNT',
    setEmailSent: 'SET_EMAIL_SENT',
    setEmailOpen: 'SET_EMAIL_OPEN',
    setEmailDelivery: 'SET_EMAIL_DELIVERY',
    setEmailBounce: 'SET_EMAIL_BOUNCE'
  },
  dispatchers: {
    get setLiveCustomerCount() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setLiveCustomerCount}`;
    },
    get setLiveItemCount() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setLiveItemCount}`;
    },
    get setEmailSent() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setEmailSent}`;
    },
    get setEmailOpen() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setEmailOpen}`;
    },
    get setEmailDelivery() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setEmailDelivery}`;
    },
    get setEmailBounce() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setEmailBounce}`;
    }
  }
};
