<template>
  <div class="vh-100 vw-100 auth-purple-glow-background">
    <div class="container h-100">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <ValidationObserver v-slot="{ handleSubmit }" class="auth-form">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-white p-3">
              <template slot="header">
                <h1 class="card-title font-weight-bold">
                  {{ signUpComplete ? 'Verify Your Account' : 'Create Account' }}
                </h1>
              </template>

              <div v-if="!signUpComplete">
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                  <label for="email">Email</label>
                  <base-input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>

                <PasswordInput
                  v-model="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  rules="required|min:8"
                />

                <PasswordInput
                  v-model="confirmPassword"
                  name="confirmPassword"
                  label="Re-enter Password"
                  placeholder="Confirm Password"
                  rules="required|confirmed:password"
                />
              </div>

              <div v-else>
                <ValidationProvider
                  name="verificationCode"
                  rules="required|min:6|max:6"
                  v-slot="{ passed, failed, errors }"
                >
                  <label for="verificationCode">Verification Code</label>
                  <base-input
                    required
                    v-model="verificationCode"
                    placeholder="Enter 6-digit code"
                    type="text"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              
              <base-button native-type="submit" class="auth-submit-button" size="lg" block :disabled="isLoading">
                <span v-if="!isLoading">{{ signUpComplete ? 'Verify' : 'Sign Up' }}</span>
                <i v-else class="fa fa-spinner fa-spin"></i>
              </base-button>

              <base-button
                v-if="signUpComplete"
                @click="resendVerificationCode"
                class="resend-button mt-2"
                size="lg"
                block
                :disabled="isResending"
              >
                {{ isResending ? 'Resending...' : 'Resend Verification Code' }}
              </base-button>

              <ExternalIdentityProvider v-if="!signUpComplete" :action="'signUp'" />

              <FormFooter :leftSide="'Sign In'" :rightSide="'Forgot Password'" />
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, min, max, confirmed } from 'vee-validate/dist/rules';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ExternalIdentityProvider from '@/components/auth/ExternalIdentityProvider.vue';
import FormFooter from '@/components/auth/FormFooter.vue';
import PasswordInput from '@/components/auth/InputPassword.vue';

import { v5 } from 'uuid';
import { Auth } from '@aws-amplify/auth';

extend('email', email);
extend('min', min);
extend('max', max);
extend('required', required);
extend('confirmed', confirmed);

export default {
  name: 'sign-up-form-comp',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
      isLoading: false,
      signUpComplete: false,
      isResending: false
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ExternalIdentityProvider,
    FormFooter,
    PasswordInput
  },
  methods: {
    async submit() {
      this.isLoading = true;

      if (!this.signUpComplete) {
        try {
          await Auth.signUp({
            username: this.email,
            password: this.password,
            attributes: {
              'custom:tenant_id': v5(this.email, v5.DNS),
              'custom:role': 'admin'
            }
          }).then(() => {
            this.signUpComplete = true;
            this.$notify({
              type: 'success',
              message: 'Account created successfully. Please check your email for the verification code',
              icon: 'tim-icons icon-bell-55'
            });
          });
        } catch (error) {
          this.$notify({
            type: 'danger',
            message: error.message,
            icon: 'tim-icons icon-bell-55'
          });
        }
      } else {
        try {
          await Auth.confirmSignUp(this.email, this.verificationCode).then(() => {
            this.$notify({
              type: 'success',
              message: 'Account verified successfully',
              icon: 'tim-icons icon-bell-55'
            });
            this.$router.push('/login');
          });
        } catch (error) {
          this.$notify({
            type: 'danger',
            message: error.message,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }

      this.isLoading = false;
    },
    async resendVerificationCode() {
      this.isResending = true;
      try {
        await Auth.resendSignUp(this.email).then(() => {
          this.$notify({
            type: 'success',
            message: 'Verification code resent successfully',
            icon: 'tim-icons icon-bell-55'
          });
        });
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'tim-icons icon-bell-55'
        });
      }
      this.isResending = false;
    },
    togglePasswordVisibility(required = false) {
      if (required) {
        this.showRequiredPassword = !this.showRequiredPassword;
      } else {
        this.showPassword = !this.showPassword;
      }
    }
  }
};
</script>
