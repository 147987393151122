export const getDefaultState = () => {
  return {
    devSQL: '',
    name: '',
    operator: 'AND',
    description: '',
    segment_external_id: '',
    count: {
      consented: 0,
      not_consented: 0
    },
    bestSellersWinning: [],
    bestSellersLosing: [],
    sql_query: '',
    sql_query_with_parent: '',

    groups: {
      GP0: {
        operator: 'AND',
        segmentChoice: null,
        fields: {},
        frequencyCheck: false,
        frequencyOperator: '=',
        frequencyValue: false
      }
    },

    otherFields: [],
    tempValue: null,

    recencyCheck: true,
    frequencyCheck: false,

    selects: {
      segment: '',
      recencyValue: {
        input: 1
      },
      frequencyValue: {
        input: 1
      },
      recencyType: {
        input: 'years',
        options: [
          { value: 'days', label: 'Days' },
          { value: 'months', label: 'Months' },
          { value: 'years', label: 'Years' }
        ]
      },
      recencyOperator: {
        input: '>',
        options: [
          // { value: '<', label: 'Later than' },
          { value: '>', label: 'In the Last' }
        ]
      },
      frequencyOperator: {
        input: '=',
        options: [
          { value: '=', label: '=' },
          { value: '>', label: '>' },
          { value: '<', label: '<' },
          { value: '<=', label: '<=' },
          { value: '>=', label: '>=' }
        ]
      },
      table: {
        input: '',
        options: [
          { value: 'customer', label: 'Customer' },
          { value: 'product', label: 'Product' },
          { value: 'segment', label: 'Segment' },
          { value: 'sale', label: 'Sales' },
          { value: 'email', label: 'Email' }
        ]
      },
      field: {
        input: '',
        type: 'local',
        options: {
          customer: [
            { value: 'user_type', label: 'Customer Type' },
            { value: 'user_id', label: 'Customer ID' },
            { value: 'tenant_created_at', label: 'Created At' },
            { value: 'surname', label: 'Surname' },
            { value: 'sub_category', label: 'Sub Category' },
            { value: 'status', label: 'Status' },
            { value: 'shipping_state', label: 'Shipping State' },
            { value: 'shipping_postcode', label: 'Shipping Postcode' },
            { value: 'shipping_country', label: 'Shipping Country' },
            { value: 'shipping_city', label: 'Shipping City' },
            { value: 'shipping_address_line_2', label: 'Shipping Address Line 2' },
            { value: 'shipping_address_line_1', label: 'Shipping Address Line 1' },
            { value: 'phone', label: 'Phone' },
            { value: 'name', label: 'Name' },
            { value: 'meta_category', label: 'Meta Category' },
            { value: 'job_title', label: 'Job Title' },
            { value: 'gender', label: 'Gender' },
            { value: 'email_consented', label: 'Email Consented' },
            { value: 'email', label: 'Email' },
            { value: 'category', label: 'Category' },
            { value: 'birthdate', label: 'Birthdate' },
            { value: 'billing_state', label: 'Billing State' },
            { value: 'billing_postcode', label: 'Billing Postcode' },
            { value: 'billing_country', label: 'Billing Country' },
            { value: 'billing_city', label: 'Billing City' },
            { value: 'billing_address_line_2', label: 'Billing Address Line 2' },
            { value: 'billing_address_line_1', label: 'Billing Address Line 1' }
          ],
          product: [
            { value: 'item_id', label: 'Product ID' },
            { value: 'name', label: 'Name' },
            { value: 'sku', label: 'SKU' },
            { value: 'description', label: 'Description' },
            { value: 'brand', label: 'Brand' },
            { value: 'meta_category', label: 'Meta category' },
            { value: 'category', label: 'Category' },
            { value: 'sub_category', label: 'Sub category' },
            { value: 'image_url', label: 'Image url' },
            { value: 'item_url', label: 'Product url' },
            { value: 'price', label: 'Price' },
            { value: 'cost', label: 'Cost' },
            { value: 'tenant_created_at', label: 'Added Datetime' },
            { value: 'channel_name', label: 'Channel Name' },
            { value: 'status', label: 'Status' },
            { value: 'stock_qty', label: 'Stock Qty' }
          ],
          segment: [
            { value: 'name', label: 'Name' },
            { value: 'id', label: 'ID' }
          ],
          sale: [
            { value: 'channel_name', label: 'Store Location' },
            { value: 'channel_type', label: 'Store Type' },
            { value: 'event_dt', label: 'Date' }
          ],
          email: [
            { value: 'delivery', label: 'Delivery' },
            { value: 'open', label: 'open' },
            { value: 'click', label: 'click' }
          ]
        }
      },
      operator: {
        input: '',
        options: {
          number: [
            { value: '=', label: '=' },
            { value: '>', label: '>' },
            { value: '<', label: '<' },
            { value: '>=', label: '>=' },
            { value: '<=', label: '<=' }
          ],
          string: [
            { value: '=', label: '=' },
            { value: '!=', label: '!=' },
            { value: 'LIKE', label: 'LIKE' },
            { value: 'NOT LIKE', label: 'NOT LIKE' }
          ]
        }
      },
      value: {
        input: '',
        type: 'string',
        options: []
      }
    },
    currentlyEditing: '',
    currentGroup: 'GP0',
    currentTable: '',

    segments: [],

    conditions: {
      table: null,
      field: null,
      operator: null,
      value: null
    }
  };
};
