export const getDefaultState = () => {
  return {
    campaigns: [],
    campaignDetails: {
      name: '',
      numericFilters: [],
      template: null,
      segments: [],
      includedFilters: [],
      excludedFilters: [],
      tags: [],
      channel: 'dodo-mail',
      type: 'None',
      manualType: 'Handpicked',
      ab: false,
      zohoSender: '',
      mailchimpSender: '',
      recommendations_id: 'latest',
      ab_distribution: 100,
      ab_objective: 'clicks',
      server_url: 'https://aws.ses',
      absolutePositioningProductIds: [],
      fromEmailAddress: null,
      subject: null
    },
    groupA: {
      objective: 'Store Visit',
      type: 'AI',
      recommendations_id: 'latest',
      manualType: 'Handpicked',
      absolutePositioningProductIds: []
    },
    groupB: {
      objective: 'Store Visit',
      type: 'AI',
      manualType: 'Handpicked',
      recommendations_id: 'latest',
      absolutePositioningProductIds: []
    },
    file: null,
    scheduledDateAndTime: null,
    checkDefaultCampaignSettings: false
  };
};
