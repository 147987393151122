import { productAnalyticsTypes } from '../../constants/types/productAnalytics';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const { setTenantProduct, setAbpp, setSegments, setTabulateSegmentUsers, updateProduct } =
  productAnalyticsTypes.actions;

export const actions = {
  [setTenantProduct]: async ({ commit }, { id }) => {
    const config = {
      body: {
        item_id: id,
        customer: null,
        month: null,
        year: null
      },

      headers: {
        'Content-Type': 'application/json'
      }
    };
    return query({
      commit,
      api: $api.analytics.product.info.post(config),
      options: {
        mutation: setTenantProduct
      }
    });
  },

  [setAbpp]: async ({ commit }, { id }) => {
    const config = {
      body: {
        item_id: id,
        customer: null,
        month: null,
        year: null
      },

      headers: {
        'Content-Type': 'application/json'
      }
    };

    return query({
      commit,
      api: $api.analytics.product.abpp.post(config),
      options: {
        mutation: setAbpp
      }
    });
  },

  [setTabulateSegmentUsers]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.analytics.product.tabulateSegmentUsers.post({
        item_id: id
      }),
      options: {
        mutation: setTabulateSegmentUsers
      }
    });
  },

  [setSegments]: async ({ commit }, { id }) => {
    const config = {
      body: {
        item_id: id,
        customer: null,
        month: null,
        year: null
      },

      headers: {
        'Content-Type': 'application/json'
      }
    };

    return query({
      commit,
      api: $api.analytics.product.topSegments.post(config),
      options: {
        mutation: setSegments
      }
    });
  },

  [updateProduct]: async ({ commit }, { productDetails }) => {
    return query({
      commit,
      api: $api.analytics.product.updateProduct.put(productDetails, productDetails.item_id),
      options: {
        mutation: setTenantProduct,
        promise: false,
        loader: setTenantProduct
      }
    });
  }
};
