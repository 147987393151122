<template>
  <div class="vh-100 vw-100 auth-purple-glow-background">
    <div class="container h-100">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <ValidationObserver v-slot="{ handleSubmit }" class="auth-form">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-white p-3">
              <template slot="header">
                <h1 class="card-title font-weight-bold">
                  {{ resetRequested ? 'Reset Password for ' + email : 'Forgot Password' }}
                </h1>
              </template>

              <div v-if="!resetRequested">
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                  <label for="email">Email</label>
                  <base-input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>

              <div v-else>
                <ValidationProvider
                  name="verificationCode"
                  rules="required|min:6|max:6"
                  v-slot="{ passed, failed, errors }"
                >
                  <label for="verificationCode">Verification Code</label>
                  <base-input
                    required
                    v-model="verificationCode"
                    placeholder="Enter 6-digit code"
                    type="text"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>

                <PasswordInput
                  v-model="newPassword"
                  name="newPassword"
                  label="New Password"
                  placeholder="New Password"
                  rules="required|min:8"
                />
              </div>

              <base-button native-type="submit" class="auth-submit-button my-2" size="lg" block :disabled="isLoading">
                <span v-if="!isLoading">{{ resetRequested ? 'Set New Password' : 'Request Password Reset' }}</span>
                <i v-else class="fa fa-spinner fa-spin"></i>
              </base-button>

              <base-button
                v-if="resetRequested"
                @click="resendVerificationCode"
                class="resend-button"
                size="lg"
                block
                :disabled="isResending"
              >
                {{ isResending ? 'Resending...' : 'Resend Verification Code' }}
              </base-button>

              <FormFooter :leftSide="'Sign In'" :rightSide="''" />
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, min, max } from 'vee-validate/dist/rules';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Auth } from '@aws-amplify/auth';

import FormFooter from '@/components/auth/FormFooter.vue';
import PasswordInput from '@/components/auth/InputPassword.vue';

extend('email', email);
extend('min', min);
extend('max', max);
extend('required', required);

export default {
  name: 'forgot-password-form-comp',
  components: {
    ValidationObserver,
    ValidationProvider,
    FormFooter,
    PasswordInput
  },
  data() {
    return {
      email: '',
      verificationCode: '',
      newPassword: '',
      isLoading: false,
      isResending: false,
      resetRequested: false
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      try {
        if (!this.resetRequested) {
          await Auth.forgotPassword(this.email).then(() => {
            this.resetRequested = true;
            this.$notify({
              type: 'success',
              message: 'Verification code sent successfully',
              icon: 'tim-icons icon-bell-55'
            });
          });
        } else {
          await Auth.forgotPasswordSubmit(this.email, this.verificationCode, this.newPassword).then(() => {
            this.$notify({
              type: 'success',
              message: 'Password reset successful',
              icon: 'tim-icons icon-bell-55'
            });
            this.$router.push('/login');
          });
        }
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'tim-icons icon-bell-55'
        });
      }
      this.isLoading = false;
    },
    async resendVerificationCode() {
      this.isResending = true;
      try {
        await Auth.forgotPassword(this.email).then(() => {
          this.resetRequested = true;
          this.$notify({
            type: 'success',
            message: 'Verification code sent successfully',
            icon: 'tim-icons icon-bell-55'
          });
        });
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'tim-icons icon-bell-55'
        });
      }
      this.isResending = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="scss">
.resend-button {
  background-color: transparent;
  color: white;
  border: 1px solid #630aa2;
}

.resend-button:hover {
  background-color: rgba(99, 10, 162, 0.1);
}
</style>
